import merge from 'deepmerge'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

import type { UIConfig, CoreConfig } from '@visiontree/mfx-auto-renderer'

import type { Questionnaire } from 'fhir/r4'

import {
  type CheckResult,
  mfxAutoRendererQuestionnaireChecks,
  specificationChecks,
} from '@/utils/questionnaireChecks'

export type Config = CoreConfig & {
  ui: UIConfig
}

export interface Source {
  id: string
  displayName: string
  jsonString: string
  unedited: boolean
  isValidJSON: boolean
  checks: {
    warningCount: number
    errorCount: number
    successCount: number
    specification: Record<string, CheckResult>
    autoRenderer: Record<string, CheckResult>
  }
  config: Config
}

export type CodeSuggestion = Record<string, unknown>

interface State {
  questionnaires: Source[]
  /** Source used by the live preview */
  active?: Source
  suggestions: CodeSuggestion[]
}

/**
 * Returns true if the string can be parsed into a valid JSON object.
 */
const checkValidJSON = (jsonString: string) => {
  try {
    const parsed = JSON.parse(jsonString) as unknown

    return typeof parsed === 'object' && !Array.isArray(parsed)
  } catch (error) {
    return false
  }
}

/**
 * Returns a simple questionnaire to give the user a hint as to what can be changed after creating
 * a new questionnaire.
 */
const buildInitialQuestionnaire = (title = 'Untitled'): Questionnaire => {
  return {
    resourceType: 'Questionnaire',
    status: 'draft',
    title,
    item: [],
  }
}

/**
 * Checks if the jsonString matches the initial questionnaire.
 */
const isInitialQuestionnaire = (jsonString: string) => {
  return JSON.stringify(buildInitialQuestionnaire(), null, 2) === jsonString
}

const getCheckResults = (sourceString: string) => {
  let sourceObject: Partial<Questionnaire>

  try {
    sourceObject = JSON.parse(sourceString) as Partial<Questionnaire>
  } catch (error) {
    // 🤷‍♂️ not parsable, but that's sometimes expected.
  }
  const specification = Object.entries(specificationChecks).reduce(
    (checks, [checkName, checkFunction]) => {
      return { ...checks, [checkName]: checkFunction(sourceObject) }
    },
    {}
  )

  const autoRenderer = Object.entries(
    mfxAutoRendererQuestionnaireChecks
  ).reduce((checks, [checkName, checkFunction]) => {
    return { ...checks, [checkName]: checkFunction(sourceObject) }
  }, {})

  const checks: CheckResult[] = [
    ...Object.values<CheckResult>(autoRenderer),
    ...Object.values<CheckResult>(specification),
  ]

  return {
    successCount: checks.filter((check) => check.success).length,
    errorCount: checks.filter(
      (check) => !check.success && check.severity === 'error'
    ).length,
    warningCount: checks.filter(
      (check) => !check.success && check.severity === 'warning'
    ).length,
    specification,
    autoRenderer,
  }
}

/**
 * Converts the questionnaire to source metadata.
 */
const buildQuestionnaireSource = (
  questionnaire = buildInitialQuestionnaire()
): Source => {
  const questionnaireString = JSON.stringify(questionnaire, null, 2)
  const initialConfig: Config = {
    ui: {
      language: 'en',
      layout: 'clinical',
    },
    log: 'warn',
  }

  return {
    id: `q-${uuid()}`,
    displayName: questionnaire.title || 'Untitled',
    jsonString: questionnaireString,
    unedited: isInitialQuestionnaire(questionnaireString),
    isValidJSON: checkValidJSON(questionnaireString),
    checks: getCheckResults(questionnaireString),
    config: initialConfig,
  }
}

/**
 * Global state for questionnaire sources.
 */
export const useSourcesStore = defineStore('sources', {
  persist: {
    /**
     * Makes sure there's always at least one questionnaire in store and that it's selected for the
     * live-preview so that there's never an empty state.
     */
    afterRestore: (context) => {
      const store = context.store as ReturnType<typeof useSourcesStore>
      const initialConfig: Config = {
        ui: {
          language: 'en',
          layout: 'clinical',
        },
        log: 'warn',
      }

      if (!store.questionnaires.length) {
        store.create()
      } else if (!store.active) {
        store.select(store.questionnaires.at(0))
      }

      store.questionnaires = store.questionnaires.map((source) => {
        return {
          ...source,
          unedited: isInitialQuestionnaire(source.jsonString),
          isValidJSON: checkValidJSON(source.jsonString),
          checks: getCheckResults(source.jsonString),
          config: source.config || initialConfig,
        }
      })

      store.clearSuggestions()
    },
  },
  state: (): State => {
    return {
      questionnaires: [],
      suggestions: [],
      active: undefined,
    }
  },
  getters: {
    emptyStateQuestionnaire(state) {
      return state.questionnaires.find((questionnaire) => {
        return questionnaire.unedited
      })
    },
    /** Returns the source with the matching id if present. */
    getById(state) {
      return (id: string) => {
        return state.questionnaires.find((questionnaire) => {
          return questionnaire.id === id
        })
      }
    },
    getSuggestedJsonString(state) {
      return (source?: Source) => {
        if (!source) {
          return ''
        }

        if (!source.isValidJSON) {
          return source.jsonString
        }

        if (!state.suggestions.length) {
          return source.jsonString
        }

        return JSON.stringify(
          merge.all(
            [JSON.parse(source.jsonString) as object, ...this.suggestions],
            {
              arrayMerge(target, source, options) {
                const output: (object | undefined)[] = []

                source.forEach((item, index) => {
                  if (typeof output[index] === 'undefined') {
                    output[index] = options?.cloneUnlessOtherwiseSpecified(
                      item as object,
                      options
                    )
                  } else if (options?.isMergeableObject(item as object)) {
                    output[index] = merge(
                      target[index] as object,
                      item as object,
                      options
                    )
                  } else if (target.indexOf(item) === -1) {
                    output.push(item as object)
                  }
                })

                return output
              },
            }
          ),
          null,
          2
        )
      }
    },
  },
  actions: {
    /**
     * Creates a new source and selects it for the live-preview.
     */
    create(questionnaire?: Questionnaire) {
      // The emptyStateQuestionnaire acts as the initial placeholder for new questionnaires, if it's
      // untouched then we'll just try to edit that one instead of making a new empty questionnaire.
      if (this.emptyStateQuestionnaire) {
        if (questionnaire) {
          this.update(this.emptyStateQuestionnaire, {
            jsonString: JSON.stringify(questionnaire, null, 2),
          })
        }

        this.select(this.emptyStateQuestionnaire)

        return this.emptyStateQuestionnaire
      }

      const source = buildQuestionnaireSource(questionnaire)

      this.questionnaires.push(source)
      this.select(source)

      return source
    },

    /**
     * Removes the source from the store. Makes sure the store isn't empty and updates the live-preview
     * source if the removed source was the active source.
     */
    remove(source?: Source) {
      if (!source) {
        return
      }

      // If the source is unedited and is the only questionnaire remaining then we'll leave it to
      // act as the empty state.
      if (source.unedited && this.questionnaires.length === 1) {
        return
      }

      this.questionnaires = this.questionnaires.filter((current) => {
        return source.id !== current.id
      })

      if (!this.questionnaires.length) {
        this.create()
      } else if (source.id === this.active?.id) {
        this.select(this.questionnaires.at(0))
      }
    },

    /**
     * Updates the source with the provided changes.
     */
    update(source: Source | undefined, changes: Pick<Source, 'jsonString'>) {
      if (!source) {
        return
      }

      const index = this.questionnaires.findIndex((current) => {
        return current.id === source.id
      })

      const updatedSource = {
        ...this.questionnaires[index],
        jsonString: changes.jsonString,
        isValidJSON: checkValidJSON(changes.jsonString),
        unedited: isInitialQuestionnaire(changes.jsonString),
      }

      if (updatedSource.isValidJSON) {
        const json = JSON.parse(changes.jsonString) as Record<string, unknown>

        updatedSource.displayName =
          typeof json?.title === 'string'
            ? json.title
            : updatedSource.displayName

        updatedSource.checks = getCheckResults(changes.jsonString)
      }

      this.questionnaires[index] = updatedSource

      return this.questionnaires[index]
    },

    updateConfig(source: Source | undefined, changes: Partial<Config>) {
      if (!source) return

      const config = {
        ...(source.config ?? {}),
        ...changes,
        ui: {
          ...(source.config?.ui ?? {}),
          ...changes.ui,
        },
      }

      if (config.ui.layout !== 'proms') {
        config.ui.promsSettings = undefined
      }

      source.config = config
      const questionnaire = this.questionnaires.find(
        (questionnaire) => questionnaire.id === source.id
      )
      if (questionnaire) {
        questionnaire.config = config
      }
    },

    /**
     * Sets the live-preview source.
     */
    select(source?: Source) {
      this.active = source
    },

    addSuggestion(suggestion: CodeSuggestion) {
      this.suggestions.push(suggestion)
    },
    clearSuggestions() {
      this.suggestions = []
    },

    applyAllSuggestions(source?: Source) {
      this.update(source, { jsonString: this.getSuggestedJsonString(source) })
      this.clearSuggestions()
    },
  },
})
