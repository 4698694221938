import { default as config0dMXrB6LalMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit/config.vue?macro=true";
import { default as codeIlaiaoboSgMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit/questionnaires/[id]/code.vue?macro=true";
import { default as configurationVjo8Uu2T3FMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit/questionnaires/[id]/configuration.vue?macro=true";
import { default as indexO9JTXTIa8FMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit/questionnaires/index.vue?macro=true";
import { default as editeB78CqCT03Meta } from "/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit.vue?macro=true";
import { default as indexdxWYNymPecMeta } from "/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/index.vue?macro=true";
import { default as _91id_93R7ti6WnpS2Meta } from "/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/preview/[id].vue?macro=true";
export default [
  {
    name: editeB78CqCT03Meta?.name ?? "edit",
    path: editeB78CqCT03Meta?.path ?? "/edit",
    children: [
  {
    name: config0dMXrB6LalMeta?.name ?? "edit-config",
    path: config0dMXrB6LalMeta?.path ?? "config",
    meta: config0dMXrB6LalMeta || {},
    alias: config0dMXrB6LalMeta?.alias || [],
    redirect: config0dMXrB6LalMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit/config.vue").then(m => m.default || m)
  },
  {
    name: codeIlaiaoboSgMeta?.name ?? "edit-questionnaires-id-code",
    path: codeIlaiaoboSgMeta?.path ?? "questionnaires/:id()/code",
    meta: codeIlaiaoboSgMeta || {},
    alias: codeIlaiaoboSgMeta?.alias || [],
    redirect: codeIlaiaoboSgMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit/questionnaires/[id]/code.vue").then(m => m.default || m)
  },
  {
    name: configurationVjo8Uu2T3FMeta?.name ?? "edit-questionnaires-id-configuration",
    path: configurationVjo8Uu2T3FMeta?.path ?? "questionnaires/:id()/configuration",
    meta: configurationVjo8Uu2T3FMeta || {},
    alias: configurationVjo8Uu2T3FMeta?.alias || [],
    redirect: configurationVjo8Uu2T3FMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit/questionnaires/[id]/configuration.vue").then(m => m.default || m)
  },
  {
    name: indexO9JTXTIa8FMeta?.name ?? "edit-questionnaires",
    path: indexO9JTXTIa8FMeta?.path ?? "questionnaires",
    meta: indexO9JTXTIa8FMeta || {},
    alias: indexO9JTXTIa8FMeta?.alias || [],
    redirect: indexO9JTXTIa8FMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit/questionnaires/index.vue").then(m => m.default || m)
  }
],
    meta: editeB78CqCT03Meta || {},
    alias: editeB78CqCT03Meta?.alias || [],
    redirect: editeB78CqCT03Meta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/edit.vue").then(m => m.default || m)
  },
  {
    name: indexdxWYNymPecMeta?.name ?? "index",
    path: indexdxWYNymPecMeta?.path ?? "/",
    meta: indexdxWYNymPecMeta || {},
    alias: indexdxWYNymPecMeta?.alias || [],
    redirect: indexdxWYNymPecMeta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93R7ti6WnpS2Meta?.name ?? "preview-id",
    path: _91id_93R7ti6WnpS2Meta?.path ?? "/preview/:id()",
    meta: _91id_93R7ti6WnpS2Meta || {},
    alias: _91id_93R7ti6WnpS2Meta?.alias || [],
    redirect: _91id_93R7ti6WnpS2Meta?.redirect || undefined,
    component: () => import("/home/runner/work/mfx-framework/mfx-framework/apps/live-preview/pages/preview/[id].vue").then(m => m.default || m)
  }
]