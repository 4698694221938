import { defineNuxtPlugin } from 'nuxt/app'
import { type Pinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

/**
 * Saves store state to the browser's local storage so that the state remains after the user leaves
 * the page and comes back again. This makes sure the user doesn't lose their work if they accidentally
 * refresh the page, or if they want to come back to preview things later.
 *
 * @see https://prazdevs.github.io/pinia-plugin-persistedstate/
 */
export default defineNuxtPlugin((nuxtApp) => {
  const pinia = nuxtApp.$pinia as Pinia

  pinia.use(piniaPluginPersistedState)
})
