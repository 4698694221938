import type r4 from 'fhir/r4'

type SuccessCheckResult = { success: true }
type WarningCheckResult = { success: false; severity: 'warning'; count: number }
type ErrorCheckResult = { success: false; severity: 'error'; count: number }
export type CheckResult =
  | SuccessCheckResult
  | WarningCheckResult
  | ErrorCheckResult

interface CheckFunction {
  (questionnaire?: Partial<r4.Questionnaire>): CheckResult
}

const checkWarning = (
  checkResult: boolean | number
): SuccessCheckResult | WarningCheckResult => {
  const isNumber = typeof checkResult === 'number'
  const success = isNumber ? checkResult === 0 : checkResult

  if (success) {
    return { success }
  }

  return {
    success,
    severity: 'warning',
    count: isNumber ? checkResult : 1,
  }
}

const checkError = (
  checkResult: boolean | number
): SuccessCheckResult | ErrorCheckResult => {
  const isNumber = typeof checkResult === 'number'
  const success = isNumber ? checkResult === 0 : checkResult

  if (success) {
    return { success }
  }

  return {
    success,
    severity: 'error',
    count: isNumber ? checkResult : 1,
  }
}

const checkItemRecursively = (
  questionnaireItem: r4.QuestionnaireItem,
  level: number,
  checkFunction: (item: r4.QuestionnaireItem, level: number) => boolean
): number => {
  let count = 0

  if (!checkFunction(questionnaireItem, level)) {
    count += 1
  }

  for (const childItem of questionnaireItem?.item || []) {
    count += checkItemRecursively(childItem, level + 1, checkFunction)
  }

  return count
}

const checkItems = (
  questionnaire: Partial<r4.Questionnaire> | undefined,
  checkFunction: (item: Partial<r4.QuestionnaireItem>, level: number) => boolean
): number => {
  let count = 0

  for (const item of questionnaire?.item || []) {
    count += checkItemRecursively(item, 0, checkFunction)
  }

  return count
}

const checkLanguagePresent: CheckFunction = (questionnaire) => {
  return checkWarning(!!questionnaire?.language)
}

const checkItemPresent: CheckFunction = (questionnaire) => {
  return checkWarning(!!questionnaire?.item?.length)
}

const checkItemLinkIdPresent: CheckFunction = (questionnaire) => {
  return checkError(
    checkItems(questionnaire, (item) => {
      return !!item.linkId
    })
  )
}

const supportedItemTypes = [
  'display',
  'boolean',
  'group',
  'decimal',
  'integer',
  'quantity',
  'date',
  'choice',
  'string',
  'text',
  'time',
] as const

const checkItemTypeValid: CheckFunction = (questionnaire) => {
  return checkError(
    checkItems(questionnaire, (item) => {
      return supportedItemTypes.some((type) => {
        return type === item.type
      })
    })
  )
}

const validItemTypes = [
  'question',
  'dateTime',
  'url',
  'attachment',
  'reference',
  ...supportedItemTypes,
] as const

const checkItemTypeSupported: CheckFunction = (questionnaire) => {
  return checkWarning(
    checkItems(questionnaire, (item) => {
      return validItemTypes.some((type) => {
        return type === item.type
      })
    })
  )
}

const checkTopLevelItemsAreGroups: CheckFunction = (questionnaire) => {
  return checkWarning(
    checkItems(questionnaire, (item, itemLevel) => {
      // Only top-level items need to be groups
      if (itemLevel > 0) {
        return true
      }

      return item.type === 'group'
    })
  )
}

const checkNoNestedGroups: CheckFunction = (questionnaire) => {
  return checkWarning(
    checkItems(questionnaire, (item, itemLevel) => {
      // Only nested items need to not be groups
      if (itemLevel === 0) {
        return true
      }

      return item.type !== 'group'
    })
  )
}

const checkResourceTypeProperty: CheckFunction = (questionnaire) => {
  return checkError(questionnaire?.resourceType === 'Questionnaire')
}

const validStatuses = ['draft', 'active', 'retired', 'unknown'] as const

const checkStatusProperty: CheckFunction = (questionnaire) => {
  return checkError(
    validStatuses.some((status) => {
      return status === questionnaire?.status
    })
  )
}

export const mfxAutoRendererQuestionnaireChecks = {
  checkLanguagePresent,
  checkItemPresent,
  checkItemTypeSupported,
  checkTopLevelItemsAreGroups,
  checkNoNestedGroups,
}

export const specificationChecks = {
  checkResourceTypeProperty,
  checkStatusProperty,
  checkItemTypeValid,
  checkItemLinkIdPresent,
}
